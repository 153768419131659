import { Card } from "@mui/material";
import React from "react";

type CardSelectProps = {
  text: string;
  idNumber: number;
  icon?: string;
  onClick: Function;
  selected?: boolean;
  radio?: boolean;
};

function CardSelect({
  text,
  idNumber,
  icon,
  onClick,
  selected = false,
  radio = false,
}: CardSelectProps) {
  const handleClick = () => {
    onClick?.(idNumber);
  };

  return (
    <Card
      className={`card-component ${selected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <div className="card-select">
        <div className="card-text-container">{text}</div>

        {icon && !radio ? <img src={icon} className="icon-img" /> : null}
        {radio ? (
          <input
            type="radio"
            name="card-select"
            checked={selected}
            onChange={handleClick}
          />
        ) : null}
      </div>
    </Card>
  );
}

export default CardSelect;

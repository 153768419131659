import { Outlet } from "react-router-dom";
import Navbar from "../components/NavBar"; // Your navbar component
import { FC } from "react";
import Footer from "../components/Footer";

interface LayoutProps<T> {
  children: React.ReactNode;
  // Add any other props your layout component expects (optional)
  data?: T; // Example of additional prop with generic type
}

const Layout = () => {
  return (
    <div className="w-100">
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;

import { Button, FormControlLabel, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { get, post, update } from "../service/apiService";
import Switch from "@mui/material/Switch";
import ConfirmSnackBar from "../components/snackbar/ConfrimSnackBar";

function NutritionalValues() {
  {
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [fullData, setFullData] = useState<any>([]);
    const [confrimSubmit, setConfrimSubmit] = useState<boolean>(false);

    const updateInfo = () => {
      const submitObj = { foodToAvoid: fullData.foodToAvoid, foodPrefer: data };
      post("Profile/meal-preference", submitObj)
        .then((res: any) => {
          setConfrimSubmit(true);
          setTimeout(() => {
            setConfrimSubmit(false);
          }, 1000);
        })
        .catch((err: any) => {});
    };

    useEffect(() => {
      get("Profile/meal-preference").then((res: any) => {
        setFullData(res);
        setData(res?.foodPrefer);
      });
    }, []);

    useEffect(() => {
      setIsChanged(true);
    }, [data]);

    const handleChange = (event: any, itemId: string) => {
      const updatedFormData = [...data];
      const index = updatedFormData.findIndex((item) => item.tag.id === itemId);
      updatedFormData[index].prefer = event.target.checked;
      setData(updatedFormData);
    };
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center mb-5">
        <div className="survey-container">
          <h3 className="text-center my-5">القيم الغذائية</h3>
          <div className="info-body">
            <FormGroup dir={"ltr"}>
              {data.length > 0 ? (
                data.map((item: any) => {
                  return (
                    <section className="d-flex flex-row align-items-center justify-content-between w-100 bg-secondary-subtle p-2 mb-5 rounded">
                      <Switch
                        checked={item.prefer}
                        color="success"
                        onChange={(e) => handleChange(e, item.tag.id)}
                      />
                      <p className=" fw-bold">{item.tag.tagDisplayName}</p>
                    </section>
                  );
                })
              ) : (
                <></>
              )}
            </FormGroup>

            <Button
              onClick={updateInfo}
              variant="contained"
              color="success"
              className=" mt-5 text-white"
              disabled={!isChanged}
            >
              حفظ
            </Button>
          </div>
          <ConfirmSnackBar open={confrimSubmit} />
        </div>
      </div>
    );
  }
}

export default NutritionalValues;

import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./state/store";
import { isExpired } from "./utils/helperFunctions";
import { login, logout } from "./state/auth/authSlice";
import PrivateRoutes from "./PrivateRoutes";
import Survey from "./pages/Survey";
import Main from "./pages/Main";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import PublicRoutes from "./PublicRoutes";
import DiscoverBreakfast from "./pages/DiscoverBreakfast";
import DiscoverSnack from "./pages/DiscoverSnack";
import DiscoverLunch from "./pages/DiscoverLunch";
import DiscoverDinner from "./pages/DiscoverDinner";
import MealInfo from "./pages/MealInfo";
import PersonalInfo from "./pages/PersonalInfo";
import NutritionalValues from "./pages/NutritionalValues";
import UndesiredIngrediants from "./pages/UndesiredIngrediants";
import Privacy from "./pages/Privacy";
import Support from "./pages/Support";
import AdminDashboard from "./pages/AdminDashboard";
import Info from "./pages/Info";

function App() {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const isTokenExpired = isExpired(token);
      if (isTokenExpired) {
        dispatch(logout());
      } else {
        dispatch(login());
      }
    }
  }, []);

  const theme = createTheme({
    palette: {
      success: {
        main: "#17bb2e",
        contrastText: "#fafafa",
      },
      secondary: {
        main: "#e0e0e0",
      },
    },
    direction: "rtl",
  });

  return (
    <ThemeProvider theme={theme}>
      {/* <RouterProvider router={router} /> */}
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path="survey" element={<Survey />} />
              <Route path="settings/info" element={<PersonalInfo />} />
              <Route
                path="settings/nutrition"
                element={<NutritionalValues />}
              />
              <Route
                path="settings/undesired"
                element={<UndesiredIngrediants />}
              />
              <Route path="/" element={<Main />} />
              <Route path="/breakfastMeals" element={<DiscoverBreakfast />} />
              <Route path="/snack" element={<DiscoverSnack />} />
              <Route path="/lunch" element={<DiscoverLunch />} />
              <Route path="/dinner" element={<DiscoverDinner />} />
              <Route
                path="/breakfastmeals/meal/:mealId"
                element={<MealInfo />}
              />
              <Route path="/snackmeals/meal/:mealId" element={<MealInfo />} />
              <Route path="/lunchmeals/meal/:mealId" element={<MealInfo />} />
              <Route path="/dinnermeals/meal/:mealId" element={<MealInfo />} />
            </Route>
          </Route>
          <Route path="notfound" />
          <Route path="privacy-ar" element={<Privacy />} />
          <Route path="privacy-en" element={<Privacy />} />
          <Route element={<PublicRoutes />}>
            <Route path="login" element={<Login />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="support" element={<Support />} />
            <Route path="info" element={<Info />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

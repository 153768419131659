import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface profileState {
  id: string;
  firstName: string;
  surname: string;
  gender: string;
  weightGoal: string;
  dateOfBirth: string;
  age: number;
  height: number;
  currentWeight: number;
  targetWeight: number;
  phoneNumber: number;
  bmr: number;
  actFac: number;
  genFac: number;
  totalDailyCalCons: number;
  targetTotalDailyCalCons: number;
  targetBmr: number;
  activityLevels: string;
  bodyTypes: string;
  days: number;
  startDate: string;
  endDate: string;
  cancelDate: string;
  subscriptionTypeLastModify: string;
  lastDeActiveDate: string;
  profileStatus: string;
  subscriptionType: string;
  chargingMethod: string;
  note: string;
  mustEnterWeight: boolean;
  registrationDate: string;
  subscribedBy: string;
  isSignUpCompleted: boolean;
}

interface userLoggedInState {
  userResource: string;
  foodPreferences: {};
  isSignUpCompleted: boolean;
  accessToken: string;
  refreshToken: string;
  tokenExpirationUtc: string;
  userProfileDto: profileState;
}

interface userState {
  user: userLoggedInState;
}

const initialState: userState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") as string)
  : {
      userResource: "",
      foodPreferences: {},
      isSignUpCompleted: false,
      accessToken: "",
      refreshToken: "",
      tokenExpirationUtc: "",
      userProfileDto: {
        id: "",
        firstName: "",
        surname: "",
        gender: "",
        weightGoal: "",
        dateOfBirth: "",
        age: 0,
        height: 0,
        currentWeight: 0,
        targetWeight: 0,
        phoneNumber: 0,
        bmr: 0,
        actFac: 0,
        genFac: 0,
        totalDailyCalCons: 0,
        targetTotalDailyCalCons: 0,
        targetBmr: 0,
        activityLevels: "",
        bodyTypes: "",
        days: 0,
        startDate: "",
        endDate: "",
        cancelDate: "",
        subscriptionTypeLastModify: "",
        lastDeActiveDate: "",
        profileStatus: "",
        subscriptionType: "",
        chargingMethod: "",
        note: "",
        mustEnterWeight: false,
        registrationDate: "",
        subscribedBy: "",
        isSignUpCompleted: false,
      },
    };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userLoggedInState>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

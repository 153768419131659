import React, { useEffect, useRef, useState } from "react";
import Burn from "../../resources/icons/burn.png";
import test from "../../resources/test.png";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { Chip, styled } from "@mui/material";
import star from "../../resources/star.png";
import clock from "../../resources/clock.png";
import CardMealDetailsPopup from "./CardMealDetailsPopup";

interface mealModel {
  mealName: string;
  img: string;
  chalories: number;
  charbs: number;
  protein: number;
  fat: number;
  id: string;
  rating: number;
  time: string;
  click: (mealName: string) => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function CardMeal({
  mealName,
  img,
  chalories,
  id,
  charbs,
  protein,
  fat,
  rating,
  time,
  click,
}: mealModel) {
  const [value, setValue] = useState<number>(0);
  const [colour, setColor] = useState<string>("success");
  const boundingRef = useRef<DOMRect | null>(null);
  const buttonStyle = {
    transform: `rotateX(var(--x-rotation)) rotateY(var(--y-rotation))`,
  };

  useEffect(() => {
    if (protein > charbs) {
      setColor("success");
      setValue(protein);
    } else if (charbs > protein) {
      setColor("warning");
      setValue(charbs);
    }
  }, [charbs, protein]);
  return (
    <div
      className="meal-card-container mt-4"
      onClick={() => click(id)}
      style={buttonStyle}
    >
      <div className="d-flex flex-column align-items-center card-angle">
        <img src={img} alt="img-meal" className="img-meal-small mb-4" />
        <hr className="horizontal-seperator mb-3" />
        <div className="d-flex  flex-column w-100">
          <div className="d-flex justify-content-around mb-3">
            <div className="d-flex flex-column text-body-tertiary  w-100">
              <div className="d-flex justify-content-around align-items-center">
                <div className="d-flex  align-items-center justify-content-center">
                  <span className="small-font-meal-card">{rating}</span>
                  <div className="vertical-seperator" />
                  <img src={star} className="star-img" />
                </div>
                <div className="d-flex  align-items-center justify-content-center ">
                  <span className="small-font-meal-card">
                    {" "}
                    {time === "Minimul"
                      ? "15 دقيقة"
                      : time === "Average"
                      ? "30 دقيقة"
                      : "45 دقيقة"}
                  </span>
                  <div className="vertical-seperator" />
                  <img src={clock} alt="clock" className="star-img" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div className="d-flex flex-row justify-content-around">
                      <CardMealDetailsPopup
                        protein={protein}
                        fat={fat}
                        carbs={charbs}
                      />
                    </div>
                  </React.Fragment>
                }
              >
                <Chip
                  label={value + "g"}
                  color={colour === "success" ? "success" : "warning"}
                  className=""
                  size="small"
                />
              </HtmlTooltip>
            </div>
          </div>
          <div className="meal-card-title me-2">
            <h6>{mealName}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMeal;

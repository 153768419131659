export const handleErrorsAccordingToStatusUserOtp = (text) => {
  if (text === "400") {
    return "Bad Request";
  }
  if (text === "401") {
    return "Unauthorized";
  }
  if (text === "403") {
    return "You have asked for the code too many times, please try again later";
  }
  if (text === "429") {
    return "You have tried to login too many times, please try again later";
  }
  if (text === "500") {
    return "Internal Server Error";
  }
  if (text === "502") {
    return "Bad Gateway";
  }
  if (text === "503") {
    return "Service Unavailable";
  }
  if (text === "504") {
    return "Gateway Timeout";
  }
  return "Unknown Error";
};

export const isExpired = (expTime) => {
  const expirationTime = expTime;
  const currentTime = Date.now();

  return currentTime > expirationTime;
};

export const handleErrorsAccordingToStatusUserLogin = (text) => {
  if (text === "400") {
    return "Bad Request";
  }
  if (text === "401") {
    return "Unauthorized";
  }
  if (text === "403") {
    return "You have asked for the code too many times, please try again later";
  }
  if (text === "404") {
    return "User with this phone number doesnt exist";
  }
  if (text === "429") {
    return "You tried too many times, please try again later ";
  }
  if (text === "502") {
    return "Bad Gateway";
  }
  if (text === "503") {
    return "Service Unavailable";
  }
  if (text === "504") {
    return "Gateway Timeout";
  }
  return "Unknown Error";
};
export const handleErrorsAccordingToPhoneNumberCheck = (text) => {
  if (text === "400") {
    return "Bad Request";
  }
  if (text === "401") {
    return "Unauthorized";
  }
  if (text === "403") {
    return "You have asked for the code too many times, please try again later";
  }
  if (text === "404") {
    return "User with this phone number doesnt exist";
  }
  if (text === "429") {
    return "You tried too many times, please try again later ";
  }
  if (text === "502") {
    return "Bad Gateway";
  }
  if (text === "503") {
    return "Service Unavailable";
  }
  if (text === "504") {
    return "Gateway Timeout";
  }
  return "Unknown Error";
};

import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    ochre: Palette["primary"];
  }

  interface PaletteOptions {
    ochre?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    ochre: true;
  }
}

const theme = createTheme({
  palette: {
    success: {
      main: "#17bb2e",
      contrastText: "#fafafa",
    },
  },
});
type CircularProgressColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "inherit";

function CircularWithLabel(props: CircularProgressProps & { value: number }) {
  const [value, setValue] = React.useState<number>(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const calculateValue = () => {
    return value > 100 ? 100 : value === 0 ? value : value;
  };
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <ThemeProvider theme={theme}>
        <CircularProgress
          variant="determinate"
          value={calculateValue()}
          color={props.color}
          size={90}
          thickness={4}
        />
      </ThemeProvider>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="circle-progress-text"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularWithLabel;

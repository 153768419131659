import { Button, IconButton } from "@mui/material";
import LogoWhite from "../resources/logo.png";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function Footer() {
  const goToTopOfThePage = () => {
    window.scrollTo(0, 0);
  };
  const navigateToPrivacyAr = () => {
    window.open("/privacy-ar", "_blank");
  };
  return (
    <div className="footer-container w-100 p-3">
      <div className="d-flex flex-row justify-content-between ">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <IconButton
            className=" text-white"
            aria-label="arrow-up"
            onClick={goToTopOfThePage}
            size="large"
          >
            <ArrowUpwardIcon />
          </IconButton>
          <p className="m-0">إنتقل للأعلى</p>
        </div>
        <div dir="ltr" className="d-flex align-items-center">
          © 2024 dietfit. All rights reserved.
        </div>

        <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigateToPrivacyAr()}
        >
          سياسة الخصوصية لدايت فيت
        </Button>
        <img src={LogoWhite} alt="logo-white" className="logo-footer" />
      </div>
    </div>
  );
}

export default Footer;

import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { patch, update } from "../service/apiService";
import ConfirmSnackBar from "../components/snackbar/ConfrimSnackBar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

function PersonalInfo() {
  const temp = localStorage.getItem("userProfileDto");
  const currentData = temp ? JSON.parse(temp) : null;
  const [currentWeight, setCurrentWeight] = useState<number>(
    currentData?.currentWeight
  );
  const [targetWeight, setTargetWeight] = useState<number>(
    currentData?.targetWeight
  );
  const [height, setHeight] = useState<number>(currentData?.height);
  const [age, setAge] = useState<number>(currentData?.age);
  const [confrimSubmit, setConfrimSubmit] = useState<boolean>(false);
  const [name, setName] = useState<string>(
    currentData?.firstName + " " + currentData?.surname
  );
  const fullNameConst = currentData?.firstName + " " + currentData?.surname;
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [changedFields, setChangedFields] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState<any>();

  const dateOfBirthToAge = () => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    console.log("age", age);
    setAge(age);
  };
  const updateInfo = () => {
    if (
      currentData?.currentWeight !== currentWeight ||
      currentData?.targetWeight !== targetWeight
    ) {
      update("Profile/Update/Weight", { currentWeight, targetWeight }).then(
        (res) => {
          setConfrimSubmit(true);
        }
      );
    }
    if (Object.keys(changedFields).length > 0) {
      const fieldsKeys = Object.keys(changedFields);
      const fieldsValues = Object.values(changedFields);
      const changedFieldsArray = fieldsKeys.map((key, index) => {
        return {
          path: `/${key}`,
          op: "replace",
          from: "string",
          value: fieldsValues[index],
        };
      });
      patch("Profile", changedFieldsArray)
        .then((res) => {
          setConfrimSubmit(true);
          localStorage.setItem("userProfileDto", JSON.stringify(res));
        })
        .then(() => {
          setTimeout(() => {
            setConfrimSubmit(false);
          }, 2000);
        });
    }
    // if (name !== fullNameConst) {
    //   const firstName = name.split(" ")[0];
    //   const surname = name.split(" ")[1];
    //   const updateObjFirstName = {
    //     path: "/firstname",
    //     op: "replace",
    //     value: firstName,
    //     from: "string",
    //   };
    //   patch("/Profile", { ...updateObjFirstName }).then((res) => {
    //     const updateObjLastName = {
    //       path: "/surname",
    //       op: "replace",
    //       value: surname,
    //     };
    //     patch("/Profile", { updateObjLastName }).then((res) => {
    //       setConfrimSubmit(true);
    //     });
    //   });
    // }
    // if (currentData?.age !== age) {
    //   const updateObj = {
    //     path: "/age",
    //     op: "replace",
    //     value: age,
    //     from: "string",
    //   };
    //   patch("/Profile", { ...updateObj }).then((res) => {
    //     setConfrimSubmit(true);
    //   });
    // }
    // if (currentData?.height !== height) {
    //   const updateObj = {
    //     path: "/height",
    //     op: "replace",
    //     value: height,
    //   };
    //   patch("/Profile", { ...updateObj }).then((res) => {
    //     setConfrimSubmit(true);
    //   });
    // }
  };

  useEffect(() => {
    if (
      currentData?.currentWeight !== currentWeight ||
      currentData?.targetWeight !== targetWeight
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [currentWeight, targetWeight]);

  useEffect(() => {
    if (dateOfBirth) {
      dateOfBirthToAge();
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (fullNameConst !== name) {
      setIsChanged(true);
      if (name.split(" ")[0] !== fullNameConst.split(" ")[0])
        setChangedFields({
          ...changedFields,
          firstname: name.split(" ")[0],
        });
      if (name.split(" ")[1] !== fullNameConst.split(" ")[1])
        setChangedFields({
          ...changedFields,
          surname: name.split(" ")[1],
        });
    } else if (dateOfBirth) {
      setChangedFields({ ...changedFields, dateOfbirth: dateOfBirth });
      setIsChanged(true);
    } else if (currentData?.height !== height) {
      setChangedFields({ ...changedFields, height: height });
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [name, age, height]);
  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-5">
      <div className="survey-container">
        <h3 className="text-center mt-5">المعلومات الشخصية</h3>
        <div className="info-body">
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="الإسم"
            onChange={(e) => setName(e.target.value)}
            defaultValue={currentData?.firstName + " " + currentData?.surname}
          />
          <DemoItem label="تاريخ الميلاد">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="D/M/YYYY"
                className="mb-2"
                value={dateOfBirth}
                onChange={(date: any) => setDateOfBirth(date)}
              />
              {/* <TextField
              id="outlined-basic"
              label="العمر/تاريخ الميلاد"
              variant="outlined"
              fullWidth
            /> */}
            </LocalizationProvider>
          </DemoItem>
          <Button
            variant="outlined"
            disabled={true}
            className="green-text mb-5"
          >
            {age} سنة
          </Button>
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="الوزن الحالي"
            defaultValue={currentData?.currentWeight}
            onChange={(e) => setCurrentWeight(parseInt(e.target.value))}
          />
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="الوزن المستهدف"
            defaultValue={currentData?.targetWeight}
            onChange={(e) => setTargetWeight(parseInt(e.target.value))}
          />
          <TextField
            id="outlined-read-only-input"
            label="الطول"
            defaultValue={currentData?.height}
            onChange={(e) => setHeight(parseInt(e.target.value))}
          />
          <Button
            onClick={updateInfo}
            variant="contained"
            color="success"
            className=" mt-5 text-white"
            disabled={!isChanged}
          >
            حفظ
          </Button>
        </div>
        <ConfirmSnackBar open={confrimSubmit} />
      </div>
    </div>
  );
}

export default PersonalInfo;

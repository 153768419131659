// httpService.js
import axios from "axios";
import { isExpired } from "../utils/helperFunctions";

const httpService = axios.create({
  baseURL: "https://api.dietfit.app",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for API token
httpService.interceptors.request.use(
  (response) => {
    // Ideally, retrieve your token from a store or environment variable
    const token = localStorage.getItem("accessToken");
    if (token) {
      // Check if token is expired
      const isTokenExpired = isExpired(token);
      if (isTokenExpired) {
        // Redirect to login page or perform any action
        localStorage.removeItem("accessToken");
        window.location.reload();
      } else {
        response.headers.Authorization = `Bearer ${token}`;
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for error handling
httpService.interceptors.response.use(
  (response) => {
    // You can modify response here
    return response;
  },
  (error) => {
    // Handle global errors
    // Example: Redirect to login if 401 Unauthorized
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.reload();
      // redirect to login page or perform any action
    }
    return Promise.reject(error);
  }
);

export default httpService;

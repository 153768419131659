import React, { useEffect, useState } from "react";
import CardStatusMain from "../components/cards/CardStatusMain";
import CardWeightMain from "../components/cards/CardWeightMain";
import PercentageCard from "../components/cards/CardPercentageMain";
import CardMeal from "../components/cards/CardMeal";
import CardCategoryMeal from "../components/cards/CardCategoryMeal";
import breakfast from "../resources/breakfast.png";
import snack from "../resources/snack.png";
import lunch from "../resources/lunch.png";
import dinner from "../resources/dinner.png";
import test from "../resources/test.png";
import CardNormal from "../components/cards/profileCards/CardNormal";
import Profile from "../resources/icons/profile.png";
import ScaleBlue from "../resources/icons/scaleblue.png";
import ForkAndSpoon from "../resources/icons/forkAndSpoon.png";
import Undesired from "../resources/icons/undesiredComponents.png";
import CardPersonalInfo from "../components/cards/profileCards/CardPersonalInfo";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { get } from "../service/apiService";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "@mui/material";
import AreaChart from "../components/chart/AreaCharts";
import { useLocation } from "react-router-dom";

function Main() {
  const navigate = useNavigate();
  const date = new Date();
  const location = useLocation();
  const splitLocation = location.pathname.split("/");
  const [isSignupCompleted, setIsSignupCompleted] = useState<boolean>(
    localStorage.getItem("isSignUpCompleted") === "true"
  );
  const currentUserString = localStorage.getItem("userProfileDto");
  const formatDate = (date: Date) => {
    if (typeof date === "object" && date instanceof Date)
      return date?.toLocaleDateString();
    else return dayjs(date).format("YYYY/MM/DD");
  };
  const [dateValue, setDateValue] = useState(new Date());

  const currentUser = currentUserString && JSON.parse(currentUserString);
  const [calStats, setCalStats] = useState<any>([]);
  const [lineData, setLineData] = useState<any>([]);

  const data = [
    {
      mainText: "وجبة الفطور",
      img: breakfast,
      text: "قم بالنقر لإختيار وجبة الفطور",
      en: "breakfastMeals",
    },
    {
      mainText: "سناك خفيف",
      img: snack,
      text: "قم بالنقر لإختيار سناك خفيف",
      en: "snack",
    },
    {
      mainText: "وجبة الغذاء",
      img: lunch,
      text: "قم بالنقر لإختيار وجبة الغذاء",
      en: "lunch",
    },
    {
      mainText: "وجبة العشاء",
      img: dinner,
      text: "قم بالنقر لإختيار وجبة العشاء",
      en: "dinner",
    },
  ];

  const onMealCardClicked = (text: string) => {
    navigate(text);
  };
  const getDateSevenDaysAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  };
  useEffect(() => {
    const isSignupCompleted = localStorage.getItem("isSignUpCompleted");
    const formatSevenDaysAgo = formatDate(getDateSevenDaysAgo()).replace(
      /\//g,
      "%2f"
    );
    const formattedIsSignupComplete = isSignupCompleted === "true";
    if (formattedIsSignupComplete === true) {
      const currentDate = formatDate(dateValue).replace(/\//g, "%2f");
      get(
        `/Profile/${currentUser.id}/Cal-Statistics?dateFrom=${currentDate}&dateTo=${currentDate}`
      ).then((res: any) => {
        setCalStats(res);
      });
      get(
        `/Profile/${currentUser.id}/Cal-Statistics-line?dateFrom=${formatSevenDaysAgo}&dateTo=${currentDate}`
      ).then((res: any) => {
        setLineData(res);
      });
    } else {
      navigate("/survey");
    }
  }, [dateValue, isSignupCompleted]);
  const navigateToMealDetails = (mealId: string) => {
    navigate(`/dinnermeals/meal/${mealId}`);
  };

  return (
    <div className="main-container">
      {isSignupCompleted ? (
        <>
          <div className="main-container-statistics">
            <div>
              <h1 className="main-container-header">كالوري تم استهلاكه</h1>
              <div className="main-page-body-container">
                <div className=" mt-5 right-statistics-container">
                  <div className="main-card-right-container ">
                    {calStats && (
                      <CardStatusMain
                        mealCal={calStats.totalMealsCal}
                        totalCal={calStats.totalCalculatedCal}
                      />
                    )}
                  </div>
                  <div className="main-card-left-container ">
                    {calStats && calStats.macronutrientsProgress && (
                      <>
                        <PercentageCard
                          color={"primary"}
                          bgColor={"#CAE5FF"}
                          title={"الدهون"}
                          perc={
                            calStats.macronutrientsProgress.fatPercentToGoal
                          }
                        />
                        <PercentageCard
                          color={"warning"}
                          bgColor={"#FDE4BF"}
                          title={"كاربوهيدرات"}
                          perc={
                            calStats.macronutrientsProgress.carbPercentToGoal
                          }
                        />
                        <PercentageCard
                          color={"success"}
                          bgColor={"#E1FFE5"}
                          title={"البروتين"}
                          perc={
                            calStats.macronutrientsProgress.proteinPercentToGoal
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <h3 className="mt-3">وجباتك اليوم</h3>
                <div className="daily-meals-container">
                  {calStats.userMeals && calStats.userMeals.length > 0 ? (
                    calStats.userMeals.map((item: any) => {
                      return (
                        <div className="single-chosen-meal-main">
                          <CardMeal
                            mealName={item.mealName}
                            img={item.imageSrc}
                            chalories={item.totalCal}
                            charbs={item.totalCarb}
                            protein={item.totalProtein}
                            fat={item.totalFat}
                            rating={item.rating}
                            time={item.timeToCook}
                            id={item.id}
                            click={navigateToMealDetails}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <h3>لا يوجد وجبات</h3>
                  )}
                </div>
              </div>
            </div>
            <div dir="ltr" className="d-flex align-items-center mt-5 ">
              <div
                className="h-100 rounded text-center"
                style={{ backgroundColor: "#D9D9D9" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={dayjs(dateValue)}
                    onChange={(newValue) => setDateValue(newValue)}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  color="success"
                  className="mt-3 w-50"
                  onClick={() => {
                    setDateValue(new Date());
                  }}
                >
                  اليوم
                </Button>
              </div>
            </div>
          </div>
          <div className="bottom-main-container d-flex flex-column mb-5 align-items-center justify-content-center p-5 mt-5">
            <h1>استكشف وصفاتنا الصحية</h1>
            <div className="main-category-container">
              {data.map((item) => {
                return (
                  <div className="mt-5 mx-5">
                    <CardCategoryMeal
                      mainText={item.mainText}
                      text={item.text}
                      img={item.img}
                      en={item.en}
                      click={onMealCardClicked}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="d-flex w-100 h-100 align-items-center justify-content-center mb-4"
            dir="ltr"
          >
            {lineData.totalCalculatedCal ? (
              <AreaChart
                totalCalculatedCal={lineData.totalCalculatedCal}
                dateTimeUtc={lineData.dateTimeUtc}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <> {isSignupCompleted}</>
      )}
    </div>
  );
}

export default Main;

import React from "react";
import test from "../../resources/test.png";
import arrow from "../../resources/arrow.png";
import { Button } from "@mui/material";

type CardCategoryMealProps = {
  mainText: string;
  text: string;
  img: string;
  en: string;
  click: (mainText: string) => void;
};

function CardCategoryMeal(props: CardCategoryMealProps) {
  return (
    <div className="meal-main-card-container">
      <div className="d-flex flex-column justify-content-around align-items-center">
        <img src={props.img} alt="meal-img" className="img-meal-small" />
        <div className="d-flex justify-content-center flex-column mt-4">
          <h6>{props.mainText}</h6>
          <div className="d-flex justify-content-around small-font">
            {props.text}
          </div>
        </div>
      </div>
      <Button
        className="w-100 mt-2"
        variant="contained"
        onClick={() => props.click(props.en)}
        color="success"
      >
        إستكشف
      </Button>
    </div>
  );
}

export default CardCategoryMeal;

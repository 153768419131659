import { Box, Chip } from "@mui/material";

import CircularWithLabel from "../progressBar/CircularWithLabel";

function PercentageCard(props: any) {
  return (
    <div className="d-flex flex-column align-items-center">
      <Box
        className="percentage-card-container mt-3"
        style={{ backgroundColor: props.bgColor }}
      >
        <div className="d-flex  align-items-center justify-content-around ">
          <div className={`d-flex flex-column align-items-center`}>
            <CircularWithLabel value={props.perc} color={props.color} />
          </div>
        </div>
        {/* <div className="d-flex flex-row justify-content-between">
        <h4>الدهون</h4>
        <div>
        <p className="fw-bold fs-6">
        <span className="success-text fw-bold fs-6 ms-4">90/</span>0
        </p>
        </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
        <p className="small-font m-0">توضيح مستوى البروتين في الجسم</p>
        <div className="range-container small-font d-flex align-items-center justify-content-center p-2">
        مستوى الكربوهيدرات
        </div>
      </div> */}
      </Box>
      <p className="fw-bold">{props.title}</p>
    </div>
  );
}

export default PercentageCard;

import { Chip } from "@mui/material";
import React from "react";

function CardMealDetailsPopup(props: any) {
  return (
    <div className="d-flex flex-row  align-items-center p-2">
      <div className="d-flex flex-column align-items-center justify-content-center ms-2">
        <Chip
          className="circle-popup"
          label={props.protein + "g"}
          color={"success"}
        />

        <span>بروتين</span>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center ms-2">
        <Chip
          className="circle-popup"
          label={props.fat + "g"}
          color={"warning"}
        />

        <span>دهون</span>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center ms-2">
        <Chip
          className="circle-popup"
          label={props.carbs + "g"}
          color={"primary"}
        />

        <span>كارب</span>
      </div>
    </div>
  );
}

export default CardMealDetailsPopup;

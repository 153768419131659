import React, { useEffect } from "react";
import Burn from "../../resources/icons/burn.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#17BB2E",
  },
}));

function CardStatusMain(props: any) {
  const calculateProgress = () => {
    return (props.mealCal * 100) / props.totalCal;
  };

  // useEffect(() => {
  //   calculateProgress();
  // }, [props.mealCal, props.totalCal]);
  return (
    <div className="main-card-container">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="img-calory-burn-main">
          <img src={Burn} alt="calory-burn" className="main-icon" />
        </div>
        <div>
          <p className="text-main-status-calories">{props.mealCal}</p>
          <p className="text-main-status-container">
            من اصل {props.totalCal} كالوري
          </p>
        </div>
      </div>
      <BorderLinearProgress
        variant="determinate"
        value={calculateProgress()}
        className="rotate-progress-bra"
      />
    </div>
  );
}

export default CardStatusMain;

import React from "react";
import { useSelector } from "react-redux";
import {
  Route,
  RouteProps,
  RouterProps,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
  Routes,
} from "react-router-dom";
import { RootState } from "./state/store";
import { Login } from "@mui/icons-material";

const PublicRoutes = () => {
  const token = localStorage.getItem("accessToken");

  return !token ? <Outlet /> : <Navigate to="/" replace />;
};

export default PublicRoutes;

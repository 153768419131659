import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(props.open);
  const { handleCancel } = props;
  const [text, setText] = React.useState(
    props.text ? props.text : "هل متأكن من إلغاء الإشتراك ؟"
  );
  const [title, setTitle] = React.useState(
    props.title ? props.title : "إلغاء الإشتراك"
  );

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    console.log("props.open", props.text);
    setText(props.text);
    setTitle(props.title);
  }, [props.text, props.title]);

  const onAgree = () => {
    handleCancel();
    setOpen(false);
  };

  React.useEffect(() => {
    console.log("props.open", props.text);
    setOpen(props.open);
  }, [props.open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAgree} autoFocus>
            متأكد
          </Button>
          <Button onClick={handleClose}>لست متأكد</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;

import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

function ConfirmSnackBar(props: any) {
  const [open, setOpen] = useState(props.open);
  const [text, setText] = useState(
    props.text ? props.text : " تم اعتماد تغييراتك"
  );
  const [duration, setDuratio] = useState(
    props.duration ? props.duration : 6000
  );
  const [position, setPosition] = useState(
    props.position ? props.position : { vertical: "bottom", horizontal: "left" }
  );
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        dir="ltr"
        anchorOrigin={position}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ConfirmSnackBar;

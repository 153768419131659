import React, { useEffect } from "react";
import Burn from "../../resources/icons/burn.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

function ProgressBarStats(props: any) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D8D5D5",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: props.color,
    },
  }));
  return (
    <div>
      <div className="w-100 d-flex justify-content-between align-items-end">
        {props.title && (
          <p className="text-main-status-container">{props.title}</p>
        )}
        <div className="d-flex flex-row ">
          <span className="m-0 p-0 number-progress-bar-status ">
            {props.number}
          </span>
          <p className="dot mx-3 my-0">.</p>
          <span className="m-0 p-0 fw-bold">{props.value}</span>
        </div>
      </div>
      <BorderLinearProgress
        variant="determinate"
        value={props.value}
        className="rotate-progress-bra"
      />
    </div>
  );
}

export default ProgressBarStats;

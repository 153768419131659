import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const text = require("../resources/textFile/Privacytxt.txt");
function Privacy() {
  const [htmlDocument, setHtmlDocument] = useState<any>();

  useEffect(() => {
    fetch(text)
      .then((response: any) => response.text())
      .then((text) => {
        setHtmlDocument(<div dangerouslySetInnerHTML={{ __html: text }} />);
      });
  }, []);

  const navigateToLogin = () => {
    window.location.href = "/";
  };

  return (
    <div className="p-5">
      <></>
      {htmlDocument}
      <Button
        className="text-success px-2"
        variant="outlined"
        onClick={navigateToLogin}
        color="success"
      >
        الرجوع للصفحة الرئيسية
      </Button>
    </div>
  );
}

export default Privacy;

import React, { useEffect, useRef, useState } from "react";
import Burn from "../../resources/icons/burn.png";
import test from "../../resources/test.png";

import { Chip, styled } from "@mui/material";
import star from "../../resources/star-meal-list.png";
import clock from "../../resources/clock.png";
import CardMealDetailsPopup from "./CardMealDetailsPopup";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface mealModel {
  mealName: string;
  img: string;
  chalories: number;
  charbs: number;
  protein: number;
  fat: number;
  id: string;
  rating: number;
  time: string;
  click: (mealName: string) => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function CardMealList({
  mealName,
  img,
  chalories,
  id,
  charbs,
  protein,
  fat,
  rating,
  time,
  click,
}: mealModel) {
  const [value, setValue] = useState<number>(0);
  const [colour, setColor] = useState<string>("success");
  const boundingRef = useRef<DOMRect | null>(null);
  const buttonStyle = {
    transform: `rotateX(var(--x-rotation)) rotateY(var(--y-rotation))`,
  };

  useEffect(() => {
    if (protein > charbs) {
      setColor("success");
      setValue(protein);
    } else if (charbs > protein) {
      setColor("warning");
      setValue(charbs);
    }
  }, [charbs, protein]);
  return (
    <div
      className="meal-card-container mt-4"
      onClick={() => click(id)}
      style={buttonStyle}
    >
      <div className="d-flex flex-column align-items-center card-meal-list">
        <div className="chip-chalories-container">
          <img
            src={img}
            alt="img-meal"
            className="img-meal-small-list mt-3 mb-4"
          />
          <div className="d-flex chip-chalories-inside justify-content-center flex-column">
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="d-flex flex-row justify-content-around">
                    <CardMealDetailsPopup
                      protein={protein}
                      fat={fat}
                      carbs={charbs}
                    />
                  </div>
                </React.Fragment>
              }
            >
              <Chip
                label={value + "g"}
                color={colour === "success" ? "success" : "warning"}
                className="m-2 chip-meal-list fw-bold"
                size="small"
              />
            </HtmlTooltip>
          </div>
        </div>
        <div className="d-flex  flex-column w-100">
          <div className="meal-card-title me-2">
            <h6>{mealName}</h6>
          </div>
          <div className="d-flex justify-content-around mb-2">
            <div className="d-flex flex-column text-body-tertiary  w-100">
              {/* <span className="me-2 ">تفاصيل الوجبة</span> */}
              <div className="d-flex me-2 align-items-center">
                <div className="d-flex  align-items-center justify-content-center">
                  <span className="small-font-meal-card-rate ms-2">
                    {rating}
                  </span>
                  <img src={star} className="star-img ms-4" />
                </div>
                <div className="d-flex  align-items-center justify-content-center ">
                  <span className="">
                    {time === "Minimul"
                      ? "15 دقيقة"
                      : time === "Average"
                      ? "30 دقيقة"
                      : "45 دقيقة"}
                  </span>
                  <div className="vertical-seperator" />
                  <img src={clock} alt="clock" className="star-img" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <img
              src={Burn}
              alt="burn"
              className="img-calory-burn-meal-list p-2"
            />
            <div className="d-flex flex-column ">
              <span className="px-1 fw-bold fs-5  me-3">{chalories}</span>
              <span className="fw-bold disc-text-tag p-1 mx-2  text-white">
                سعرة حرارية
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMealList;

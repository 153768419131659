import React, { useEffect, useState } from "react";
import { get } from "../service/apiService";
import CardCategoryMeal from "../components/cards/CardCategoryMeal";
import CardMeal from "../components/cards/CardMeal";
import { Meal } from "../models/MealModel";
import { useNavigate } from "react-router-dom";
import CardMealList from "../components/cards/CardMealList";

function DiscoverSnack() {
  const navigate = useNavigate();
  const [mealsData, setMealsData] = useState([]);
  useEffect(() => {
    get("profile/meals")
      .then((res: any) => {
        setMealsData(res.snacksMeals.meals);
      })
      .catch((e) => {});
  }, []);

  const navigateToMealDetails = (mealId: string) => {
    navigate(`/snackmeals/meal/${mealId}`);
  };

  return (
    <div className="main-container">
      <h3 className="text-center pt-5">استكشاف الوجبات الخفيفة</h3>
      <div className="discover-all-meal-cards-container">
        {mealsData.map((item: Meal) => {
          return (
            <div className="discover-meal-card ">
              <CardMealList
                mealName={item.mealName}
                img={item.imageSrc}
                chalories={item.totalCal}
                charbs={item.totalCarb}
                protein={item.totalProtein}
                rating={item.rating}
                time={item.timeToCook}
                fat={item.totalFat}
                id={item.id}
                click={navigateToMealDetails}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DiscoverSnack;

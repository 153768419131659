import React, { useEffect, useState } from "react";
import { get } from "../service/apiService";

function Info() {
  const [htmlDocument, setHtmlDocument] = useState<any>();
  useEffect(() => {
    get("/info").then((response: any) => {
      console.log("inside inside ");
      console.log("here", response);
      setHtmlDocument(response.content);
    });
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlDocument }}
      className="padding-info"
    />
  );
}

export default Info;

import React, { PureComponent, useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

// { active, payload, label }
const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="custom-tooltip">
        <p> {`التاريخ : ${props.payload[0].payload.name}`}</p>
        <p className="label-tooltip">{`سعرة حرارية : ${props.payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

interface ChartDataModel {
  dateTimeUtc: string[];
  totalCalculatedCal: string[];
}
function AreaCharts(props: ChartDataModel) {
  const [data, setData] = useState<any>([]);

  const mergeTwoArraysIntoOneObject = () => {
    let result = props?.dateTimeUtc.map((dateTimeUtc, index) => ({
      name: dateTimeUtc,
      chalories: parseInt(props.totalCalculatedCal[index]),
    }));
    setData(result);
  };
  useEffect(() => {
    mergeTwoArraysIntoOneObject();
  }, []);

  return (
    <AreaChart
      width={1000}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />

      <Tooltip content={<CustomTooltip />} />
      <Area
        type="monotone"
        dataKey="chalories"
        stroke="#0dcfff"
        fill="#0DFFE7"
      />
    </AreaChart>
  );
}

export default AreaCharts;

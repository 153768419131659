import { Card } from "@mui/material";
import React from "react";

type CardSelectProps = {
  text: string;
  idNumber: number;
  icon: string;
  onClick: Function;
  selected?: boolean;
};

function CardSelect({
  text,
  idNumber,
  icon,
  onClick,
  selected = false,
}: CardSelectProps) {
  const handleClick = () => {
    onClick?.(idNumber);
  };

  return (
    <Card
      className={`m-5 card-sex-component ${selected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <div className="card-sex-select">
        {icon && <img src={icon} className="icon-sex-img " />}
        {text}
      </div>
    </Card>
  );
}

export default CardSelect;

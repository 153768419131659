import { Navigate, Outlet, Route, Routes, redirect } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./layout/Layout";
import { RootState } from "./state/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const completed = localStorage.getItem("isSignUpCompleted");
  const token = localStorage.getItem("accessToken");
  const isAdmin = localStorage.getItem("isAdmin");

  useEffect(() => {
    if (!token) {
      redirect("/login");
    }
    if (token && isAdmin !== "true" && completed === "false") {
      redirect("/survey");
    }
  }, [completed, token, isAdmin]);

  return token ? <Outlet /> : <Navigate to="/login" replace />;
};
export default PrivateRoutes;

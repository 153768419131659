import React from "react";
import ProgressBarStats from "../progressBar/ProgressBarStats";
import { Button } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { post } from "../../service/apiService";

function CardMealInfoStats(props: any) {
  const [mealTimeText, setMealTimeText] = React.useState(
    props.mealTime === "breakfast"
      ? "الفطور"
      : props.mealTime === "lunch"
      ? "الغداء"
      : props.mealTime === "dinner"
      ? "العشاء"
      : "الوجبة الخفيفة"
  );
  const [mealTimeArabic, setMealTimeArabic] = React.useState(
    props.mealTime === "breakfast"
      ? "فطور"
      : props.mealTime === "lunch"
      ? "غداء"
      : props.mealTime === "dinner"
      ? "عشاء"
      : "وجبة الخفيفة"
  );
  const addMeal = () => {
    post("/Profile/meal/open", {
      mealId: props.mealId,
      openTime: props.mealTime,
    })
      .then((res) => {
        props.setMealData(res);
        props.setDishes(res?.dishes[0]);
      })
      .catch((e) => {
        props.openDialog(true);
        if (e.response.status === 409)
          props.setError("الرجاء تحديث الوزن الحالي");
      });
  };
  return (
    <div className="meal-info-status-container px-5 pt-3 rounded">
      <p className=" header-meal-info-status">القيم الغذائية</p>
      <div>
        <ProgressBarStats
          color={"#F89C15"}
          value={props.mealCarb}
          title="الكربوهيدرات"
        />
      </div>
      <div className="mt-4">
        <ProgressBarStats
          color={"#17BB2E"}
          value={props.mealProtein}
          title="البروتين"
        />
      </div>
      <div className="mt-4">
        <ProgressBarStats value={props.mealFat} title="الدهون" />
      </div>
      <p className="text-danger font-weight-bold">{` يمكنك إضافة   ${mealTimeArabic} واحد يوميا`}</p>

      <Button
        variant="contained"
        color="success"
        className="mt-3 w-100"
        disabled={props.isOpened || props.hideAddButton}
        onClick={() => addMeal()}
      >
        <div className="d-flex w-100 h-100 justify-content-between align-items-center">
          <p className="p-0 m-0">{`إضافة الى ${mealTimeText} القادم`}</p>
          <KeyboardReturnIcon />
        </div>
      </Button>
    </div>
  );
}

export default CardMealInfoStats;
